import { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useRouteError } from 'react-router-dom';
import { profileEnv } from '../config/getConfig';
import { Text } from './common/Text';
import { Title } from './common/Title';
import * as Sentry from '@sentry/react';
import { ContactSupportLink } from './ContactSupportLink';

// This hook hides the webpack dev server overlay when an error occurs.
// This is temporary solution until we migrate from react-script to run server in prod.
const useHideDevToolsOverlay = () => {
  useEffect(() => {
    if (profileEnv !== 'prod') return;

    const errorOverlayElement = document.getElementById('webpack-dev-server-client-overlay');
    if (!errorOverlayElement) return;
    errorOverlayElement.style.display = 'none'; // Hide the element initially
  }, []);
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  margin-top: 150px;
`;

const ErrorDescription = styled(Text)`
  white-space: break-spaces;
  max-width: 800px;
  margin: 20px auto;
`;

const HomeLink = styled(Link)`
  text-decoration: none;
`;

const SupportContainer = styled.div`
  display: flex;
  margin: 20px auto;
`;

const SupportText = styled.span`
  margin-right: 8px;
`;

export const ErrorBoundaryComponent = () => {
  useHideDevToolsOverlay();

  const error = useRouteError();
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ErrorContainer>
      <Title $size={'medium'}>Oops! Something went wrong 😵</Title>
      <ErrorDescription $variant={'primary'}>
        If you are seeing this page, it means there was an unexpected error. We apologize for
        inconvenience and would like to help resolve the issue as soon as possible.
      </ErrorDescription>
      <SupportContainer>
        <SupportText>Please contact our support team at</SupportText>
        <ContactSupportLink text={'support@zencoder.ai'} />
      </SupportContainer>
      <HomeLink to="/">{'<'} Go back to Home</HomeLink>
    </ErrorContainer>
  );
};
