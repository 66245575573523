import { Link, useSearchParams } from 'react-router-dom';

import { styled } from 'styled-components';
import { usePaymentStatus } from '../hooks/usePaymentStatus';
import Spinner from '../components/Spinner';
import { Title } from '../components/common/Title';
import { Text } from '../components/common/Text';
import { ContactSupportLink } from '../components/ContactSupportLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 32px;
  margin-top: 150px;
  width: 100%;
`;

const Loader = styled(Spinner)`
  div& {
    margin-top: 40px;
    width: auto;
    height: auto;
  }
`;

const Message = styled(Text)`
  white-space: break-spaces;
  max-width: 800px;
`;

export const Pending = () => {
  return (
    <>
      <Title>Payment Pending</Title>
      <Text>Payment is being processed. Please wait...</Text>
      <Loader />
    </>
  );
};

export const PaymentError = () => {
  return (
    <>
      <Title $size={'medium'}>Oops! Something went wrong 😞</Title>
      <Message>
        We were unable to verify your payment. This could be due to a network issue or a problem on
        our end. Please check if your plan has been successfully updated. If not, feel free to
        contact us for assistance.
      </Message>
      <Link reloadDocument={true} to={'/portal/manage-subscription'}>
        Check your subscription here
      </Link>
      <ContactSupportLink />
    </>
  );
};

const Success = () => {
  setTimeout(() => {
    window.location.href = '/portal/manage-subscription';
  }, 3000);

  return (
    <>
      <Title>Thank you!</Title>
      <Text>
        Your payment was successful. You will be redirected shortly or you can click the link below.
      </Text>
      <Link reloadDocument={true} to={'/portal/manage-subscription'}>
        Back to Subscription Page
      </Link>
    </>
  );
};

const Status = ({ status }: { status?: string }) => {
  return status === 'complete' ? <Success /> : <PaymentError />;
};

export const PaymentStatus = () => {
  const [params] = useSearchParams();

  const sessionId = params.get('session_id');
  const sessionStatus = usePaymentStatus(sessionId);

  return (
    <Container>
      {sessionStatus.loading ? <Pending /> : <Status status={sessionStatus.status} />}
    </Container>
  );
};
