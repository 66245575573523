import { useAuth } from '@frontegg/react';
import { SUPPORTED_UTM_KEYS, UTMParamKey } from './useStoreUtmParams';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useApiClient } from './useApiClient/useApiClient';

type AttachUtmPayload = Partial<Record<UTMParamKey, string>>;

const getAttachUtmPayload = (): AttachUtmPayload =>
  SUPPORTED_UTM_KEYS.reduce<AttachUtmPayload>((acc, key) => {
    const value = localStorage.getItem(key);
    if (!!value) {
      acc[key] = value;
    }
    return acc;
  }, {});

export const useAttachUtm = () => {
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const payload = getAttachUtmPayload();

    const attachUtm = async () => {
      try {
        await apiClient.post('/api/attach_utm', payload, {
          keepalive: true,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    const hasUtmData = Object.values(payload).some((value) => Boolean(value));

    hasUtmData && attachUtm();
  }, [apiClient, isAuthenticated]);

  return null;
};
