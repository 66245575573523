import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { Button } from '../../components/common/Button';
import { ContentWrapper } from '../../components/common/ContentWrapper';
import { Divider } from '../../components/common/Divider';
import { Label } from '../../components/common/Label';
import { Text } from '../../components/common/Text';
import { Title } from '../../components/common/Title';
import { RootContext } from '../../components/PortalLayout/PortalLayout';
import { useApiClient } from '../../hooks/useApiClient/useApiClient';
import { pluralize } from '../../utils/pluralize';
import { MembersTable } from './MembersTable';
import { Heading, TitleSection } from './styled';
import { TeamMember } from './types';

const ManageSeatsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const useMembers = () => {
  const apiClient = useApiClient();
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      let response: TeamMember[] | null = null;
      try {
        response = await apiClient.get<TeamMember[]>('/api/team_members');
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        response && setMembers(response);
        setLoading(false);
      }
    };

    fetchMembers();
  }, [apiClient]);

  return {
    loading,
    members,
  };
};

export const Members = () => {
  const navigate = useNavigate();

  const { subscription, members = [] } = useContext(RootContext);

  const seatsNumber = subscription?.quantity || 1;
  const availableSeats = seatsNumber - members.length;

  const isPaidPlan = subscription?.plan.metadata?.alias !== 'free' && !subscription?.trial_end;
  const isOverLimit = availableSeats < 0;

  const seatsDelta = Math.abs(availableSeats);
  const pluralizedSeatsText = pluralize(seatsDelta, 'seat');

  return (
    <ContentWrapper>
      <Heading>
        <Label>Team</Label>
        <TitleSection>
          <Title $size={'medium'}>
            {members.length} {pluralize(members.length, 'member')}
          </Title>
          <ManageSeatsButtonsContainer>
            {!isOverLimit && isPaidPlan ? (
              <Text $variant="secondary">
                {seatsDelta} {pluralizedSeatsText} available
              </Text>
            ) : isPaidPlan && isOverLimit ? (
              <Text $variant="error">
                Over the limit by {`${seatsDelta} ${pluralizedSeatsText}`}
              </Text>
            ) : null}
            <Button
              $variant="primary"
              $filled={true}
              onClick={() => navigate('/dashboard#/admin-box/users')}
            >
              + Invite
            </Button>
          </ManageSeatsButtonsContainer>
        </TitleSection>
      </Heading>
      <Divider />
      <MembersTable members={members} />
    </ContentWrapper>
  );
};
