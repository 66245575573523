import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { LoaderPage } from '../components/LoaderPage/LoaderPage';
import { useApiClient } from '../hooks/useApiClient/useApiClient';

export const WelcomePage = () => {
  const apiClient = useApiClient();

  useEffect(() => {
    (async () => {
      try {
        await apiClient.post('/api/set_welcome_shown', {});
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        window.location.replace('https://zencoder.ai/download');
      }
    })();
  }, [apiClient]);

  return <LoaderPage />;
};
