import { useAuth } from '@frontegg/react';
import { getConfig } from '../../config/getConfig';
import { useMemo } from 'react';
import { useAsyncError } from '../useAsyncError';

const config = getConfig();

export const useApiClient = () => {
  const { user } = useAuth();
  const throwError = useAsyncError();

  return useMemo(
    () => ({
      get: async <T>(path: string, params?: Record<string, string>): Promise<T | null> => {
        try {
          if (!user) throw Error('User is not authenticated');

          const query = new URLSearchParams(params).toString();

          const response = await fetch(`${config.apiBaseUrl}${path}${query ? `?${query}` : ''}`, {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          });

          const contentType = response.headers.get('Content-Type');
          if (response.status === 200 && contentType && contentType.includes('application/json')) {
            return await response.json();
          }

          return null;
        } catch (e) {
          throwError(new Error('Failed to fetch data', { cause: e }));
          return null;
        }
      },
      post: async <T>(
        path: string,
        data: Record<string, unknown>,
        options: RequestInit = {}
      ): Promise<T | null> => {
        try {
          if (!user) throw Error('User is not authenticated');

          const response = await fetch(`${config.apiBaseUrl}${path}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.accessToken}`,
            },
            ...options,
          });

          const contentType = response.headers.get('Content-Type');
          if (response.status === 200 && contentType && contentType.includes('application/json')) {
            return await response.json();
          }

          return null;
        } catch (e) {
          throwError(new Error('Failed to fetch data', { cause: e }));
          return null;
        }
      },
    }),
    [user, throwError]
  );
};
