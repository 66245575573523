import { styled } from 'styled-components';
import { TeamMember } from './types';
import { useMemo } from 'react';
import { ContactSupportLink } from '../../components/ContactSupportLink';
import { Text } from '../../components/common/Text';

const TableContainer = styled.div`
  margin: 20px 32px;
`;

const Table = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr<{ $bottomBorder?: boolean }>`
  ${({ $bottomBorder: bottomBorder = false }) =>
    bottomBorder
      ? {
          borderBottom: '1px solid #e0e0e0;',
        }
      : {}}
`;

const TableHeader = styled.th`
  text-align: left;
  color: #222;
  opacity: 0.5;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const TableCell = styled.td`
  padding: 24px 0;
  text-align: left;
`;

const LoadingError = styled.p`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

type Props = {
  members: TeamMember[];
};

export const MembersTable = ({ members }: Props) => {
  const sortedMembers = useMemo(() => {
    return members.sort((a) => (a.userRoles.includes('Admin') ? -1 : 1));
  }, [members]);

  if (!members || !members.length)
    return (
      <LoadingError>
        <Text>Failed to load team members. Please try again later or contact us at </Text>
        <ContactSupportLink text={'support@zencoder.ai'} />
      </LoadingError>
    );

  return (
    <TableContainer>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Role</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {sortedMembers.map((member, index) => (
            <TableRow key={member.id} $bottomBorder={index !== members.length - 1}>
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell>{member.userRoles.join(', ')}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};
