import styled from 'styled-components';

const StyledContactSupportLink = styled.a`
  display: inline;
`;

export const ContactSupportLink = ({ text = 'Contact Support' }: { text?: string }) => {
  return (
    <StyledContactSupportLink href="mailto:support@zencoder.ai">{text}</StyledContactSupportLink>
  );
};
