import { useSearchParams } from 'react-router-dom';

export const SUPPORTED_UTM_KEYS = [
  'utm_term',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_adgroup',
  'ip_country',
  'ip_country_code',
] as const;

export type UTMParamKey = (typeof SUPPORTED_UTM_KEYS)[number];

export const useStoreUtmParams = () => {
  const [searchParams] = useSearchParams();

  SUPPORTED_UTM_KEYS.forEach((key) => {
    const value = searchParams.get(key);

    if (value) {
      localStorage.setItem(key, value);
    }
  });
};
