import './instrument';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { RootLayout } from './components/RootLayout/RootLayout';
import React from 'react';
import { PortalLayout } from './components/PortalLayout/PortalLayout';
import { ManageSubscriptionPage } from './pages/ManageSubscriptionPage';
import { AuthPage } from './pages/AuthPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PaymentStatus } from './pages/PaymentStatusPage';
import { WrongRedirectUrl } from './pages/WrongRedirectUrl';
import { PricingPage } from './pages/PricingPage/PricingPage';
import { WelcomePage } from './pages/WelcomePage';
import { AdminPanelView } from './components/AdminPanelView/AdminPanelView';
import { ErrorBoundaryComponent } from './components/ErrorBoundaryComponent';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />} errorElement={<ErrorBoundaryComponent />}>
      <Route path="portal" element={<PortalLayout />}>
        <Route path="manage-subscription" element={<ManageSubscriptionPage />} />
        <Route path="pricing" element={<PricingPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
        <Route path="payment-status" element={<PaymentStatus />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/dashboard" element={<AdminPanelView />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/unsafe-redirect-url" element={<WrongRedirectUrl />} />
      <Route path="/*" element={<AuthPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
