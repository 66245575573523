import devConfig from './config.dev';
import localConfig from './config.local';
import prodConfig from './config.prod';

type ProfileEnv = 'dev' | 'local' | 'prod';

export const profileEnv = (process.env.REACT_APP_FORGOOD_PROFILE || 'dev') as ProfileEnv;

export const getConfig = () => {
  return profileEnv === 'prod' ? prodConfig : profileEnv === 'dev' ? devConfig : localConfig;
};
