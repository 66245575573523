const safeHosts = [
  'vscode://zencoderai.zencoder',
  'vscode-insiders://zencoderai.zencoder',
  'vscodium://zencoderai.zencoder',
  'cursor://zencoderai.zencoder',
  'windsurf://zencoderai.zencoder',
  'http://127.0.0.1',
  'http:/127.0.0.1',
];

export const isSafeRedirectUrl = (url: string | null) => {
  return url ? safeHosts.some((host) => url.startsWith(host)) : false;
};
