import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { getConfig } from './config/getConfig';

const { apiBaseUrl } = getConfig();

if (process.env.REACT_APP_FORGOOD_PROFILE !== 'local') {
  Sentry.init({
    dsn: 'https://c8f4163d08eb1197fb107aa1d15d28b4@o4508092486057984.ingest.de.sentry.io/4508160977403984',
    environment: process.env.REACT_APP_FORGOOD_PROFILE || 'dev',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, apiBaseUrl],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      // trying to prevent "Event `Event` (type=error) captured as promise rejection"
      // ending up in the Sentry Issues list
      if (hint.originalException instanceof Event) return null;
      return event;
    },
  });
}
