import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback } from 'react';
import styled from 'styled-components';
import { getConfig } from '../../config/getConfig';
import { useApiClient } from '../../hooks/useApiClient/useApiClient';
import { useAsyncError } from '../../hooks/useAsyncError';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const useFetchClientSecret = () => {
  const apiClient = useApiClient();
  const throwError = useAsyncError();
  return useCallback(async () => {
    const result = await apiClient.get<{ clientSecret: string }>(
      '/api/stripe/create_checkout_session'
    );

    if (!result) {
      throwError(new Error('No client secret found'));
      // return just so the tsc knows that the result is not nullish
      return '';
    }

    return result.clientSecret;
  }, [apiClient, throwError]);
};

const { stripePubKey } = getConfig();
const stripePromise = loadStripe(stripePubKey);

export const CheckoutPage = () => {
  const fetchClientSecret = useFetchClientSecret();
  const options = { fetchClientSecret };

  return (
    <Wrapper id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Wrapper>
  );
};
