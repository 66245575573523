import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useApiClient } from '../../hooks/useApiClient/useApiClient';
import Stripe from 'stripe';
import { LoaderPage } from '../LoaderPage/LoaderPage';
import { useStripePlans } from '../../hooks/useStripePlans';
import { Plan } from '../../pages/PricingPage/types';
import { TeamMember } from '../../pages/ManageSubscriptionPage/types';
import { useMembers } from '../../pages/ManageSubscriptionPage/Members';

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 8px;
`;

export type SubscriptionInfo = {
  subscription?: Stripe.Subscription & { plan: Stripe.Plan; quantity: number };
  paymentMethod?: Stripe.PaymentMethod;
  plans?: Plan[];
  members?: TeamMember[];
};

type RootContextType = SubscriptionInfo;

export const RootContext = createContext<RootContextType>({} as RootContextType);

const usePageData = () => {
  const apiClient = useApiClient();
  const [loading, setLoading] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>();

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      const response = await apiClient.get<SubscriptionInfo>('/api/stripe/subscription_info');

      response && setSubscriptionInfo(response);
      setLoading(false);
    };

    getSubscriptionInfo();
  }, [apiClient]);

  return { subscriptionInfo, loading };
};

export const PortalLayout = () => {
  const navigate = useNavigate();
  const { subscriptionInfo, loading: pageDataLoading } = usePageData();
  const { members = [], loading: membersLoading } = useMembers();
  const { plans } = useStripePlans();

  const contextData = useMemo(() => {
    return {
      ...subscriptionInfo,
      plans: plans,
      members,
    };
  }, [subscriptionInfo, plans, members]);

  const loading = pageDataLoading || membersLoading;
  if (!loading && !subscriptionInfo) navigate('/dashboard');

  return (
    <Wrapper>
      <RootContext.Provider value={contextData}>
        <Header />
        {loading ? <LoaderPage /> : <Outlet />}
      </RootContext.Provider>
    </Wrapper>
  );
};
