import { useState, useCallback } from 'react';

/**
 * A hacky way to make ErrorBoundary catch async errors
 */
export const useAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [error, setError] = useState();

  return useCallback(
    (e: unknown) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};
